import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './containers/Home/Home.js';
import Login from './containers/Login/Login.js';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Inventory from './containers/Inventory/Inventory.js';
import Reports from './containers/Reports/Reports.js';

ReactDOM.render(
  <BrowserRouter basename="/">
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/inventory">
        <Inventory/>
      </Route>
      <Route path="/reports">
        <Reports />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
