import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { Form, FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { Button } from 'reactstrap';
import Register from '../Register/Register';
import axios from 'axios';
//import {express} from 'express';
//import UserProfiles from './users.json';
import './Login.css';
import { withRouter } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleRegisterOpen = React.createRef()
        this.state = {
            username: '',
            password: '',
            loginStatus: 'Please login!',
            token: "",
            name: "",
            surName: "",
            shopName: "",
            userCode: "",
            emailAddress: "",
            db: "",
            register: ""
        };
    }

    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value,
        });
    };

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value,
        });
    };

    setThis = () => {

    }
    openRegister = () => {
        this.setState({ register: "register" });
        this.handleRegisterOpen.current.handleRegisterOpen();
    }

    onRegisterClose = ()=>{
        this.setState({ register: "" });
    }

    componentDidMount =(e)=> {
        /*         const express = require('express');
                const app = express();
        
                // enable CORS for all routes
                app.use((req, res, next) => {
                    res.setHeader('Access-Control-Allow-Origin', '*');
                    next();
                }); */
                

    };

    authenticate = async (e) => {
        e.preventDefault();
        if (this.state.register === "") {

            var found = false;
            if ((this.state.username === "") || (this.state.password === "")) {
                alert("Please provide valid inputs")
                return false;
            }
            else {
                e.preventDefault();
                this.setState({ username: this.username })
                let config = {
                    headers: { 'Accept': 'application/json' },
                    params: { "username": this.state.username, "password": this.state.password }
                }

                //const url = "http://localhost/reactcrudphp/api/getUser.php";
                const url = "https://phpapi.bktsystems.com/api/getUser.php";
                const myUrl = '/home';
                let params = new URLSearchParams(myUrl.search)
                const response = await axios.get(url, config
                )
                if (Array.isArray(response.data)) {
                    found = true;
                    console.log(response.data[0]['db']);
                    params.append('db', response.data[0]['db']);
                    this.props.history.push({
                        pathname: '/home', state: {
                            email: response.data.emailaddress, username: this.state.username,
                            fullname: response.data[0]['fullname'], db: response.data[0]['db']
                        }

                    });
                }
                else {
                    this.setState({
                        loginStatus: 'Login failed! Inavlid User/Pass',
                    });
                    console.log("Not allowed")
                }
            }

        }
        else {
            return false
        }

    }

    render() {
        return (
            <div className="mainDivHome" style={{ paddingTop: "25vh" }}>
                <div style={{textAlign:"center"}}>
                    <label>
                        <h3>POS App  | {this.state.loginStatus}</h3>
                    </label>
                </div>
                <div className="login-form">
                    <Form onSubmit={this.authenticate}>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                                id="username"
                                name="username"
                                placeholder="e-mail address"
                                onChange={this.handleUsernameChange}
                                type="email"
                                value={this.state.username}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Enter password"
                                onChange={this.handlePasswordChange}
                                type="password"
                            />
                        </FormGroup>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ backgroundColor: "rgb(208, 247, 228)", width: "100%" }} type="submit">Submit</Button>
                        </div>
                        <div style={{ paddingTop: "30px" }}>
                            <span >
                                <Button style={{ backgroundColor: "rgb(67, 129, 67)", width: "100%", color: "rgb(208, 247, 228)", border: "none" }} text="Register" onClick={() => this.openRegister()}>Register Here</Button>
                            </span>
                        </div>
                        <div>
                            <Register
                                ref={this.handleRegisterOpen}
                                onRegisterClose={this.onRegisterClose}
                            />
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
