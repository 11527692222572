import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
//import { Button } from 'reactstrap';
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import Dashboard from "../Dashboard/Dashboard";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import './Reports.css'

class Reports extends Component {
    constructor(props) {
        super(props);
        this.dashboardOpen = React.createRef()
        const today = new Date().toLocaleDateString('en-uk', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
        this.state = {
            date: today,
            startDate: new Date(),
            endDate: new Date(),
            salesItems: [],
            allSales: [],
            reportData: [],
            reportCount: "--",
            products: [],
            sales: [],
            items: [],
            salesRecords: [],
            db: ""
        }
    }
    salesItems = [];


    componentDidMount() {
        // console.log("DBBBBB"+this.props.history.location.state.db)
        this.setState({ db: this.props.history.location.state.db, items: this.props.history.location.state.items, salesRecords: this.props.history.location.state.salesRecords })
        // console.log("Products" + JSON.stringify(this.props.history.location.state.items));
        // this.getSalesItems();
        this.getDashboard();


    }
    //salesItems = [{"product":"Castle Light 750ml","price":"22","qty":"2","total":44,"balanceStock":46,"prodId":"33"},{"product":"Flying Fish lemon","price":"27.00","qty":"1","total":27,"balanceStock":43,"prodId":"24"}]; //JSON.parse("[" + items + "]");

    getDashboard = (e) => {
        var dashBorad = [];
        let salesRec = this.props.history.location.state.salesRecords;
        let prodItems = this.props.history.location.state.items;
        prodItems.map(function (item, ii) {
            var totalCount = 0;
            var obj = {};
            salesRec.map(function (sl, i) {
                let salesItemss = JSON.parse("[" + sl.items + "]");

                //  console.log(">>>>>"+JSON.stringify(salesItemss));    
                salesItemss.map(function (line, i) {
                    if (line.product.includes(item.label)) {
                        totalCount += parseFloat(line.qty);
                    }

                    return totalCount
                })


            })

            obj["item"] = item.label;
            obj["total"] = totalCount;
            //  console.log(item.label + "Total count: <<<<< " + totalCount);
            dashBorad.push(obj);

        })
        dashBorad.sort(function (a, b) {
            var keyA = parseFloat(a.total),
                keyB = parseFloat(b.total);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });

        // console.log(dashBorad[0].item)
        return dashBorad;

    }

    dashBorad = this.getDashboard();
    topOne = `${this.dashBorad[0].item}
    ${this.dashBorad[0].total}`;
    topTwoo = `${this.dashBorad[1].item}
    ${this.dashBorad[1].total}`;
    topThree = `${this.dashBorad[2].item}
    ${this.dashBorad[2].total}`;
    topFour = `${this.dashBorad[3].item}
    ${this.dashBorad[3].total}`;

    getSalesItems = (salesItems) => {
        let qty = 0;
        return (salesItems.map(function (qty, i) {
            console.log("<<<<<" + qty.qty)
            return qty = qty.qty
        }))
    }

    openDashboard = () => {
        this.dashboardOpen.current.dashboardOpen();
    }

    handleStartChange = (date) => {
        //const startDate = this.formatDate(date);
        this.setState({
            startDate: date
        });
        // console.log("MMMMMM" + date)
    }
    handleEndChange = (date) => {
        // const endDdate = this.formatDate(date);
        this.setState({
            endDate: date
        });
        //   console.log("MMMMMM" + date)
    }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        this.setState({ startDate: [year, month, day].join('-') })
        return [year, month, day].join('-');
    }

    clearReports() {
        this.setState({ reportData: [], reportCount: "--" });
        document.getElementById('btnSubmit').disabled = false;


    }
    getReport = async (e) => {
        let config = {
            headers: { 'Accept': 'application/json' },
            params: { "startdate": this.state.startDate, "enddate": this.state.endDate, "db": this.state.db }

        }
        // const url = "http://localhost/reactcrudphp/api/getSalesReport.php";
        const url = "https://phpapi.bktsystems.com/api/getSalesReport.php";
        const response = await axios.get(url, config
        )

        if (Array.isArray(response.data)) {
            this.setState({ reportData: response.data, reportCount: response.data.length })
            document.getElementById('btnSubmit').setAttribute("disabled", "disabled")
        }
        if (response.data === 'No data found') {
            document.getElementById('btnSubmit').setAttribute("disabled", "disabled")
            this.setState({ reportCount: response.data })
        }
    }
    formatDataSale = (strdata, strP) => {
        let sData = JSON.parse("[" + strdata + "]") //JSON.stringify(strdata);
        //  let formattedData = JSON.stringify(data).replace("{", "").replace("}", "");
        return (sData.map((ms) => ms.strP + ", "));
    }
    goToInventory = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/inventory', state: {
                username: this.props.history.location.state.username, fullname: this.props.history.location.state.fullname,
                db: this.state.db, items: this.state.items, salesRecords: this.state.salesRecords
            }
        });
    }
    goToHomePage = (e) => {
        this.props.history.push({ pathname: '/home', state: { fullname: this.props.history.location.state.fullname, db: this.state.db } })
    }
    appLogout = (e) => {
        e.preventDefault();
        this.props.history.push({ pathname: '/', state: { fullname: "" } })
    }
    render() {
        return (
            <div className="mainDivHome">
                <div style={{ width: "100%", textAlign: "left" }}>
                    <span>
                        <Button style={{ paddingLeft: "5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} onClick={this.goToHomePage}>Home</Button>
                        <Button style={{ paddingLeft: "5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} onClick={this.goToInventory}>Inventory</Button>
                        <Button style={{ paddingLeft: "5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(67, 129, 67)" }} >Reports</Button>
                        <Button style={{ paddingLeft: "5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(160, 46, 46)" }} text="Logout" onClick={this.appLogout}>Logout</Button>
                    </span>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <span><label>User: {this.props.history.location.state.fullname}</label></span>
                    <span><label>, today is {this.state.date}</label></span>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <label><h3>Top Sales Dashboard</h3></label>
                </div>
                <FormGroup>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <Input
                            style={{
                                height: '10vh', textAlign: "center", backgroundColor: "rgb(208, 247, 228)", fontWeight: "bold",
                                borderRadius: "15px", color: "green"
                            }}
                            readOnly={true}
                            type='textarea'
                            id='top_one'
                            name='top_one'
                            height='20px'
                            value={this.topOne}
                            onClick={()=>this.openDashboard()}
                        />
                        <Input
                            style={{ height: '10vh', textAlign: "center", backgroundColor: "rgb(208, 247, 228)", fontWeight: "bold", borderRadius: "15px", color: "olive" }}
                            readOnly={true}
                            type='textarea'
                            id='top_twoo'
                            name='top_one'
                            value={this.topTwoo}
                            onClick={()=>this.openDashboard()}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <Input
                            style={{ height: '10vh', textAlign: "center", backgroundColor: "rgb(208, 247, 228)", fontWeight: "bold", borderRadius: "15px", color: "grey" }}
                            readOnly={true}
                            type='textarea'
                            id='top_three'
                            name='top_three'
                            value={this.topThree}
                            onClick={()=>this.openDashboard()}
                        />
                        <Input
                            style={{ height: '10vh', textAlign: "center", backgroundColor: "rgb(208, 247, 228)", fontWeight: "bold", borderRadius: "15px", color: "brown" }}
                            readOnly={true}
                            type='textarea'
                            id='top_four'
                            name='top_four'
                            value={this.topFour}
                            onClick={()=>this.openDashboard()}
                        />
                    </div>
                </FormGroup>
                <div style={{ paddingTop: "10px" }}>
                    <label><h3>Reports</h3></label>
                </div>
                <div style={{ padding: "10px 0", margin: "0 auto", justifyContent: "space-between", width: "100%" }}>
                    <span >
                        <label style={{ marginRight: "5px" }}>
                            From Date:
                        </label>
                        <DatePicker className="datePicker"
                            dateFormat="YYYY/MM/dd" // Also corrected date format
                            selected={this.state.startDate}
                            onChange={this.handleStartChange}
                        />
                    </span>
                </div>
                <div style={{ padding: "10px 0", margin: "0 auto", justifyContent: "space-between", width: "100%" }}>
                    <span >
                        <label style={{ marginRight: "20px" }}>
                            To Date:
                        </label >
                        <DatePicker className="datePicker"
                            dateFormat="YYYY/MM/dd" // Also corrected date format
                            selected={this.state.endDate}
                            onChange={this.handleEndChange}
                        />
                    </span>

                </div>
                <div style={{ paddingTop: "20px" }}>
                    <div style={{ paddingBottom: "10px" }}>
                        <span>
                            <label>
                                <b>Sales From {JSON.stringify(this.state.startDate).substring(0, 11) + " "}
                                    To {JSON.stringify(this.state.endDate).substring(0, 11)} : {this.state.reportCount}</b>
                            </label>
                        </span>
                    </div>

                    <table style={{ borderBottom: "1px solid white", width: "100%" }}>
                        <th style={{ height: "5px", borderBottom: "1px solid white", borderTop: "1px solid white", borderRight: "1px solid white", borderLeft: "1px solid white" }}>Receipt No</th>
                        <th style={{ height: "5px", borderBottom: "1px solid white", borderTop: "1px solid white", borderRight: "1px solid white" }}>Items Details</th>
                        <th style={{ height: "5px", borderBottom: "1px solid white", borderTop: "1px solid white", borderRight: "1px solid white" }}>Date</th>
                        <th style={{ height: "5px", borderBottom: "1px solid white", borderTop: "1px solid white", borderRight: "1px solid white" }}>Cashier</th>
                        {/*                         {this.state.reportData.forEach(function (arrayItem) {
                           // if (arrayItem.balanceStock !== undefined) {
                                var id = arrayItem.id;
                                var items = arrayItem.items;
                                var modified = arrayItem.modified
                                var username = arrayItem.username;
                                console.log(">>>>>"+items)
                                return (<tr>
                                    <td>{arrayItem.id}</td>
                                    <td>{arrayItem.items}</td>
                                    <td>{arrayItem.modified}</td>
                                    <td>{arrayItem.username}</td>
                                </tr>
                                )
                           // }
                        })} */}
                        {this.state.reportData.map(function (item) {
                            //  console.log(typeof item.items);
                            //  let itemm = this.formatDataSale(item.id);
                            let myObject = JSON.parse("[" + item.items + "]")
                            return (<tr style={{ height: "5px", color: "white", borderBottom: "1px solid white", borderTop: "1px solid white" }}>
                                <td style={{ borderColor: "white" }}>{item.id}</td>
                                {/* <td>{JSON.stringify(item.items).replace("{", "").replace("}", "")}</td> */}
                                <td style={{ borderColor: "white" }}>{myObject.map((ms) => <div>{ms.qty + " * " + ms.product + " ~ R" + ms.total.toFixed(2)}</div>)}</td>
                                <td style={{ borderColor: "white" }}>{item.modified.substring(0, 19)}</td>
                                <td style={{ borderColor: "white" }}>{item.username}</td>
                            </tr>)
                        })}

                    </table>
                </div>

                <div style={{ paddingTop: "20px" }}>
                    <div >
                        <Button style={{ backgroundColor: "rgb(208, 247, 228)", color: "rgb(255, 195, 0)", width: "100%" }} text="Delete" onClick={() => this.clearReports()}>Clear</Button>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <Button id="btnSubmit" style={{ backgroundColor: "rgb(208, 247, 228)", width: "100%" }} text="Submit" onClick={this.getReport}>Submit</Button>
                    </div >
                </div>
                <div id="dashboard" >
                    {<Dashboard
                        ref={this.dashboardOpen}
                        shopname={this.state.shopname}
                        username={this.state.username}
                        getDashboard={this.getDashboard}
                        date ={this.state.date}
                    />}
                </div>
            </div>
        )
    }
}
export default withRouter(Reports)