import React from "react";
import { Component } from "react";
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import Modal from 'react-modal';
class Receipt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            username: ""
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.clearPurchase();
        this.props.loadProduct();
    };

    handleOpen = () => {
        this.setState({ open: true });

    };
    // lines = JSON.stringify(this.props.cart).replace("[", "").replace("]", "")
    //itemLlines = JSON.parse("[" + this.lines + "]") 
    //open =this.props.open
    render() {

        return (
            <div>
                <div >
                    <Modal ariaHideApp={false} isOpen={this.state.open} onClose={this.handleClose}>
                        <>                            
                            <div >
                                <span className="shopname"><h4>{this.props.shopname}</h4></span>
                            </div>
                            <h4><u>Receipt</u></h4>
                            <div style={{ paddingTop: "5px" }}>
                                <span className="date">Date: {this.props.date}</span>
                            </div>
                            <div style={{ borderBottom: "ridge",  paddingBottom: "10px" }}>
                                <span className="username">Cashier: {this.props.username}</span>
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                                <span className="cart">Item Lines {this.props.cart}</span>
                            </div>
                            <div style={{ paddingTop: "10px", borderBottom: "ridge", paddingBottom: "10px" }}>
                                <span className="total">Total: <b>R{this.props.total}</b></span>
                            </div>
                        </>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ width: "100%" }}
                                onClick={this.handleClose}>
                                Close
                            </Button>
                        </div>
                    </Modal>
                </div>

            </div>
        );

    }

}
export default Receipt