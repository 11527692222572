import React from "react";
import { Component } from "react";
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import Modal from 'react-modal';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            username: ""
        }
    }

    dashboardClose = () => {
        this.setState({ open: false });
        // this.props.clearPurchase();
    };

    dashboardOpen = () => {
        this.setState({ open: true });

    };

    componentDidMount = () => {
       //  console.log("sales today are "+JSON.stringify(this.props.getDashboard()))
    }
     dashboardData = this.props.getDashboard();

    render() {

        return (
            <div>
                <div >
                    <Modal ariaHideApp={false} isOpen={this.state.open} onClose={this.dashboardClose}>
                        <>
                            <div >                                
                            </div>
                            <h4><u>Breakdown of items sold</u></h4>
                            <div style={{ paddingTop: "5px" }}>
                                <span >Date: {this.props.date}</span>
                            </div><hr style={{border:"2px solid green"}}/>           
                            <div style={{ paddingTop: "5px"}}>
                                <table style={{width:"100%", }}>
                                    <th>Items</th><th>Total  Sold</th>
                                {this.dashboardData.map((ms) => <tr><td style={{width:"50%", border:"2px solid green"}}>{ms.item} </td><td style={{textAlign:"center", width:"50%", border:"2px solid green"}}>{ms.total}</td>                            
                                </tr>)} 
                                </table>                                
                            </div>
                        </>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ width: "100%" }}
                                onClick={this.dashboardClose}>
                                Close
                            </Button>
                        </div>
                    </Modal>
                </div>

            </div>
        );

    }

}
export default Dashboard