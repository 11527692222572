import React from "react";
import { Component } from "react";
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            gender: "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            mobilePhone: "",
            country: "",
            city: "",
            userName: "",
            username: "",
            password: "",
            passWord: "",
            businessName: "",
            registrations: [],
            countryCities: []
        }
    }
    genders = [
        { label: "Female", value: "Female" },
        { label: "Male", value: "Male" },
        { label: "Other", value: "Other" }
    ]

    countries = [
        { label: "Angola", value: "Angola" },
        { label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
        { label: "Congo Democratic", value: "Congo Democratic" },
        { label: "Congo Republic", value: "Congo Republic" },
        { label: "Ghana", value: "Ghana" },
        { label: "South Africa", value: "South Africa" },
        { label: "Zimbabwe", value: "Zimbawe" },
    ]

    countriesCities = [
        { label: "Angola", value: "Luanda" },
        { label: "Angola", value: "Cubango" },
        { label: "Cote d'Ivoire", value: "Abidjan" },
        { label: "Congo Democratic", value: "Kinshasa" },
        { label: "Congo Democratic", value: "Lubumbashi" },
        { label: "Congo Democratic", value: "Mbuji Mayi" },
        { label: "Congo Republic", value: "Brazzaville" },
        { label: "Congo Republic", value: "Pointe Noire" },
        { label: "Congo Republic", value: "Dolisie" },
        { label: "Congo Republic", value: "Nkayi" },
        { label: "Congo Republic", value: "Ouesso" },
        { label: "Ghana", value: "Accra" },
        { label: "South Africa", value: "Johannesburg" },
        { label: "South Africa", value: "Cape Town" },
        { label: "South Africa", value: "Tswane" },
        { label: "South Africa", value: "Limpopo" },
        { label: "South Africa", value: "East London" },
        { label: "South Africa", value: "Durban" },
        { label: "South Africa", value: "Port Elizabeth" },
        { label: "Zimbabwe", value: "Bulawayo" },
        { label: "Zimbabwe", value: "Harare" }
    ]

    handleRegisterClose = () => {
        this.setState({ open: false, firstName: "", lastName: "", emailAddress: "", mobilePhone: "", 
            gender: "", userName: "", passWord: "", businessName: "", country: "", city: "" });
        document.getElementById('successRegstration').style.display = "none";
        document.getElementById('failRegstration').style.display = "none";
        this.props.onRegisterClose();
        // this.props.loadProduct();
    };
    componentDidMount = async () => {
        this.getAllRegistrations();

    }

    getCountryCities = (country) => {

        let countryCity = [];
        {
            this.countriesCities.map(item => {
                var obj = {};
                if (item.label === country) {
                    obj["label"] = item.value;
                    obj["value"] = item.value;
                    countryCity.push(obj)
                }
            })
        }
        this.setState({ countryCities: countryCity });
    }

    setUserName = (e) => {
        this.setState({
            emailAddress: e.target.value, username: e.target.value
        });
    };

    setPassword = (e) => {
        this.setState({
            passWord: e.target.value, password: e.target.value
        });
    };

    handleRegisterOpen = () => {
        this.setState({ open: true });
    };
    setGenderChange = (e) => {
        this.setState({ gender: e });
    }
    setFirstName = (e) => {
        this.setState({ firstName: e.target.value });
    }
    setLastName = (e) => {
        this.setState({ lastName: e.target.value });
    }
    setPhoneNumber = (e) => {
        this.setState({ mobilePhone: e.target.value });
    }
    setBusinessName = (e) => {
        this.setState({ businessName: e.target.value });
    }
    setCountry = (e) => {
        this.setState({ country: e });
        this.getCountryCities(e);
    }
    setCity = (e) => {
        this.setState({ city: e });
    }

    saveRegistration = async (e) => {
        e.stopPropagation();
        this.sendRegistration();
    }
    getAllRegistrations = async (e) => {
        let config = {
            headers: { 'Accept': 'application/json' },
            params: { "db": "issgroup_pos" }

        }
        //const url = "http://localhost/reactcrudphp/api/getAllRegistrations.php";
        const url = "https://phpapi.bktsystems.com/api/getAllRegistrations.php";
        const response = await axios.get(url, config);
       // console.log(response.data);
        this.setState({ registrations: response.data });
    }

    sendRegistration = async (e) => {
        let userFound = false;
        if (this.state.emailAddress !== "") {
            this.state.registrations.map(item => {
                if (item.emailaddress.replace(/\s/g, '').toLowerCase() === this.state.emailAddress.replace(/\s/g, '').toLocaleLowerCase()) {
                    return userFound = true;
                }

            })
        }

        if (userFound === true) {
            alert("Registration with this user account exists already!");
            return false;
        }
        else {
            if ((this.state.gender === "") || (this.state.firstName === "") || (this.state.emailAddress === "") || (this.state.mobilePhone === "")
                || (this.state.businessName === "") || (this.state.passWord === "") || (this.state.city === "") || (this.state.country === "")) {
                alert("Please provide all required fields!")
                return false;
            }
            else {

                let config = {
                    headers: { 'Accept': 'application/json' },
                    params: {
                        'firstname': this.state.firstName,
                        'lastname': this.state.lastName,
                        'emailaddress': this.state.emailAddress,
                        'mobilephone': this.state.mobilePhone,
                        'gender': this.state.gender,
                        'username': this.state.userName,
                        'password': this.state.passWord,
                        'businessname': this.state.businessName,
                        'country': this.state.country,
                        'city': this.state.city

                    }
                }
                // const url = "https://phpapi.bktsystems.com/api/getRegister.php";
                const url = "https://phpapi.bktsystems.com/api/getRegister.php";
                const response = await axios.get(url, config
                )
                console.log(response.data);
                this.setState({ firstName: "", lastName: "", emailAddress: "", mobilePhone: "", gender: "", userName: "", passWord: "", businessName: "", country: "", city: "" });
                if (response.data.includes("Registration sucessfully received.") === true) {
                    document.getElementById('successRegstration').style.display = "block";
                    //alert("Thank you, your registration request has been received. We will contact you once your account is setup.");
                }
                else {
                    //alert("There was an error, please try again later.");
                    document.getElementById('failRegstration').style.display = "block";
                }
            }
        }


    }

    render() {

        return (
            <div>
                <div >
                    <Modal ariaHideApp={false} isOpen={this.state.open} onClose={this.handleRegisterClose}>
                        <Form >
                            <div>
                                <div style={{ textAlign: "center" }}><Label> <h4><u>Registration</u></h4></Label></div>                                                                <FormGroup>
                                    <Label for='gender'>Gender</Label>
                                    <Select
                                        options={this.genders}
                                        maxMenuHeight="100%"
                                        defaultInputValue=""
                                        onChange={opt => this.setGenderChange(opt.label)}
                                    ></Select>
                                </FormGroup>
                                <FormGroup>
                                    <Label for='firstname'>First Name</Label>
                                    <Input
                                        type='text'
                                        id='firstName'
                                        name='firstName'
                                        value={this.state.firstName}
                                        onChange={this.setFirstName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='lastname'>Last Name</Label>
                                    <Input
                                        type='text'
                                        id='lastName'
                                        name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.setLastName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='phonenumber'>Phone Number</Label>
                                    <Input
                                        type='number'
                                        id='phonenumber'
                                        name='phonenumber'
                                        placeholder="+271234567896"
                                        value={this.state.mobilePhone}
                                        onChange={this.setPhoneNumber}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="username">Username</Label>
                                    <Input
                                        id="userName"
                                        name="userName"
                                        placeholder="e-mail address"
                                        onChange={this.setUserName}
                                        type="email"
                                        value={this.state.emailAddress}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Set Password</Label>
                                    <Input
                                        id="password"
                                        name="password"
                                       // placeholder="Set password"
                                        onChange={this.setPassword}
                                        type="password"
                                        value={this.state.passWord}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="businessname">Business Name</Label>
                                    <Input
                                        id="businessName"
                                        name="businessName"
                                      //  placeholder="Business name"
                                        onChange={this.setBusinessName}
                                        type="text"
                                        value={this.state.businessName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='country'>Country</Label>
                                    <Select
                                        options={this.countries}
                                        maxMenuHeight="100%"
                                        defaultInputValue=""
                                        onChange={opt => this.setCountry(opt.label)}
                                    ></Select>
                                </FormGroup>
                                <FormGroup>
                                    <Label for='city'>City</Label>
                                    <Select
                                        options={this.state.countryCities}
                                        maxMenuHeight="100%"
                                        defaultInputValue=""
                                        onChange={opt => this.setCity(opt.label)}
                                    ></Select>
                                </FormGroup>
                                <div id='successRegstration' style={{ textAlign: "center", color: "green", display: "none" }}><Label> <h4>Registration sent, thank you!</h4></Label></div>
                                <div id='failRegstration' style={{ textAlign: "center", color: "red", display: "none" }}><Label> <h4>Registration failed!</h4></Label></div>
                                <div style={{ paddingTop: "20px" }}>
                                    <Button style={{ backgroundColor: "rgb(9, 59, 16)", width: "100%" }} type="submit" onClick={this.saveRegistration}>Submit</Button>
                                </div>

                                <div style={{ paddingTop: "20px" }}>
                                    <Button style={{ width: "100%" }}
                                        onClick={this.handleRegisterClose}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Modal>
                </div>
            </div>
        );

    }

}
export default Register