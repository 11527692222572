import React, { Component } from 'react';
import deleteIcon from './delete.png';
import './Home.css';
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { withRouter } from 'react-router-dom';
//import { Dropdown } from 'react-day-picker';
import Receipt from '../Receipt/Receipt';
import Override from '../Override/Override';
import { Dropdown } from 'semantic-ui-react'
import axios from 'axios';
import Select from 'react-select';

class Home extends Component {
    constructor(props) {
        super(props);
        this.handleOpen = React.createRef()
        this.overrideOpen = React.createRef()
        const today = new Date().toLocaleDateString('en-uk', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
        const dateWithoutSecond = new Date().toLocaleDateString('en-uk', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit' });
        const dateTime = new Date().toLocaleDateString('en-uk', { hour: '2-digit', minute: '2-digit' });

        // SET STATE
        this.state = {
            date: today,
            dateWithoutSecond: dateWithoutSecond,
            dateTime: dateTime,
            product: "",
            qty: "",
            price: 0,
            total: 0,
            selectedProduct: "",
            products: [],
            foods: [],
            drinks: [],
            cart: [],
            refreshKey: 0,
            sales: [],
            salesRecords: [],
            username: "bruno",
            stock: 0,
            balanceStock: 0,
            categItem: "",
            prodId: "",
            db: "",
            open: false,
            shopname: "",
            items: []
            // todaySales:0
        }
    }

    cart = [];
    foods = [];
    drinks = [];
    sales = [];
    items = [];

    strPurchase = "Purchase >>";

    handleReset = () => {
        this.forceUpdate();

    }
    // TO DELETE
    /*     getMyParams = () => {
            let params = new URL(document.location.toString()).searchParams;
            let db = params.get("db");
            return db;
        } */
    loadProduct = async (e) => {
        // console.log("jnnnndb" + this.props.history.location.state.db)
        let config = {
            headers: { 'Accept': 'application/json' },
            params: { "db": this.props.history.location.state.db }

        }
        //const url = "http://localhost/reactcrudphp/api/getProduct.php";
        const url = "https://phpapi.bktsystems.com/api/getProduct.php";
        const response = await axios.get(url, config)

        // console.log("rseponse"+response.data)

        if (Array.isArray(response.data)) {
            //  console.log(">>>>>"+response.data)
            this.setState({ products: response.data });
            this.setState({ data: response.data });
            //  console.log(this.state.products);

            Object.keys(response.data).map((item, index) => {
                var obj = {};
                if (response.data[item].category === 'Food') {
                    this.foods.push(response.data[index]);
                }
                if (response.data[item].category === 'Drinks') {
                    this.drinks.push(response.data[index]);
                }
                obj["label"] = response.data[item].name
                obj["value"] = response.data[item].name
                this.items.push(obj)
            })
            //  console.log(this.items)
            this.setState({ drinks: this.drinks, foods: this.foods, stock: this.state.data.stock, status: this.state.data.status, items: this.items });
            this.items = [];
        }

    }

    componentDidMount = () => {

        let shopname = "";
        if (this.props.history.location.state.db === 'issgroup_pos') {
            shopname = "ISS Group";
        }
        if (this.props.history.location.state.db === 'issgroup_sohopos') {
            shopname = "SOHO Lounge";
        }
        if (this.props.history.location.state.db === 'issgroup_prudpos') {
            shopname = "Chez Prud";
        }
        //this.getMyParams();
        this.loadProduct();
        this.getSalesToday();
        this.setState({ db: this.props.history.location.state.db, username: this.props.history.location.state.fullname, shopname: shopname })

        //Receipt = new Receipt()
        // console.log("...." + (this.items))

    }

    // GET TODAY'S SALES
    getSalesToday = async (e) => {
        let config = {
            headers: { 'Accept': 'application/json' },
            params: { "today": this.state.today, "db": this.props.history.location.state.db }

        }
        // const url = "http://localhost/reactcrudphp/api/getSalesToday.php";
        const url = "https://phpapi.bktsystems.com/api/getSalesToday.php";
        const response = await axios.get(url, config
        )

        if (Array.isArray(response.data)) {
            response.data.map(sale => (
                this.sales.push(sale.items)
            ))
            let rsales = JSON.parse("[" + this.sales + "]")
            this.setState({ sales: rsales, salesRecords: response.data });
            // this.getTodaySale();
        }
    }
    handleOverrides = () => {
        this.overrideOpen.current.overrideOpen();
    }
    Receipt = () => {
        this.handleOpen.current.handleOpen();
    }

    //Insert Purchase
    insertPurchase = async (e) => {
        const userData = {
            'username': this.props.history.location.state.fullname,
            'items': JSON.stringify(this.state.cart).replace("[", "").replace("]", ""),
            'db': this.state.db
        };
        var qs = require('qs');
        // const url = "http://localhost/reactcrudphp/api/insertPurchase.php";
        const url = "https://phpapi.bktsystems.com/api/insertPurchase.php";
        const response = await axios.post(url, qs.stringify(userData))
        console.log(response.data)
    }
    //Update stock
    updateProductStock = async (e) => {

        let db = this.state.db;
        this.cart.forEach(function (arrayItem) {
            if (arrayItem.balanceStock !== undefined) {
                var qs = require('qs');
                var stock = arrayItem.balanceStock;
                var id = arrayItem.prodId;
                const userData = {
                    'id': id,
                    'stock': stock,
                    'db': db,
                };
                // const url = "http://localhost/reactcrudphp/api/updateProductStock.php";
                const url = "https://phpapi.bktsystems.com/api/updateProductStock.php";
                const response = axios.post(url, qs.stringify(userData))
                // console.log("+++++++"+JSON.stringify(response.data))
            }

        });

    }

    // GETTERS AND SETTERS
    /*     setSelectChange = (e) => {
            alert(e)
            //Get index and price of selected product
             var index = this.state.products.findIndex(p => p.name === e.target.value);
            const price = this.state.products[Object.keys(this.state.products)[index]].price;
            const balanceStock = this.state.products[Object.keys(this.state.products)[index]].stock;
            const categItem = this.state.products[Object.keys(this.state.products)[index]].category;
            const prodId = this.state.products[Object.keys(this.state.products)[index]].id;
            const status = this.state.products[Object.keys(this.state.products)[index]].state;
    
            if ((categItem === 'Drinks') && (balanceStock < 10)) { alert("Consider topping-up! Only " + balanceStock + " left on " + e.target.value) }
            // Set the selected price value in state
            this.setState({ product: e.target.value, price: price, selectedProduct: e.target.value, balanceStock: balanceStock, categItem: categItem, prodId: prodId, status: status }, function () {
                const input = document.getElementById("qty");
                input.focus()
                // console.log("Selected value is " + prodId)
                // console.log("bbbbb" + this.props.history.location.state.db + this.props.username)
            }); 
        } */
    setSelectChange = (e) => {

        //Get index and price of selected product
        var index = this.state.products.findIndex(p => p.name === e);
        const price = this.state.products[Object.keys(this.state.products)[index]].price;
        const balanceStock = this.state.products[Object.keys(this.state.products)[index]].stock;
        const categItem = this.state.products[Object.keys(this.state.products)[index]].category;
        const prodId = this.state.products[Object.keys(this.state.products)[index]].id;
        const status = this.state.products[Object.keys(this.state.products)[index]].state;
        // console.log(balanceStock)

        if ((categItem === 'Drinks') && (parseFloat(balanceStock) === 0)) {
            alert(e + " is out of stock!");
            return false;
        }

        if ((categItem === 'Drinks') && (balanceStock < 10)) {
            alert("Consider topping-up! Only " + balanceStock + " left on " + e);
        }
        // Set the selected price value in state
        this.setState({ product: e, price: price, selectedProduct: e, balanceStock: balanceStock, categItem: categItem, prodId: prodId, status: status }, function () {
            const input = document.getElementById("qty");
            input.focus()
        });

    }

    handleDrinksChange = (e) => {

        if (e.target.checked === true) {
            let filteredDrinks = [];
            Object.keys(this.state.data).map((item, index) => {
                if (this.state.data[item].category === 'Drinks') {
                    var obj = {};
                    obj["label"] = this.state.data[index].name
                    obj["value"] = this.state.data[index].name
                    filteredDrinks.push(obj)

                }
            })

            this.setState({ items: filteredDrinks });
            document.getElementById('food').checked = false;
        }
        else {
            this.setState({ products: this.state.data });
        }
    }

    handleFoodChange = (e) => {
        let unFilteredFood = [];
        unFilteredFood = this.state.items;
        if (e.target.checked === true) {
            let filteredFood = [];
            Object.keys(this.state.data).map((item, index) => {
                if (this.state.data[item].category === 'Food') {
                    var obj = {};
                    obj["label"] = this.state.data[index].name
                    obj["value"] = this.state.data[index].name
                    filteredFood.push(obj)
                }
            })
            this.setState({ items: filteredFood });
            /*             Object.keys(this.state.data).map((item, index) => {
                            if (this.state.data[item].category === 'Food') {
                                this.foods.push(this.state.data[index]);
            
                            }
                        }) */
            // this.setState({ products: this.foods });
            document.getElementById('drinks').checked = false;
        }
        if (e.target.checked === false) {

            this.setState({ items: unFilteredFood });
        }
    }

    setQty = (e) => {

        if ((e.target.value.length > 3) || (parseFloat(e.target.value) === 0)) {
            alert("Zero and 3 digit numbers are not valid input, please correct!")
            return false
        }
        else {
            const strTotal = e.target.value * this.state.price;
            this.setState({ qty: e.target.value, total: strTotal }, function () {
            });
        }

    }

    setPrice = (e) => {
        this.setState({ price: e.target.value.toFixed(2) }, function () {
        });
    }

    purchase = () => {
        // console.log("Purchase <<<<<" + JSON.stringify(this.cart));
    }

    addToCart = () => {
        var reg = /^\d*\.?\d+$/;

        /* if ((this.state.selectedProduct === "") || (this.state.selectedProduct.includes("Please Select") === true) || (this.state.selectedProduct.includes("Select") === true)) { */
        if ((this.state.selectedProduct === "") || (this.state.selectedProduct === "") || (this.state.selectedProduct.includes("Select") === true)) {
            alert("Please choose a product!");
            this.setState({ qty: "", total: 0 });
            return false;
        }
        if (reg.test(this.state.qty) === false) {
            alert("Please enter a valid quantity value!");
            this.setState({ qty: "", total: 0 });
            return false;
        }

        // console.log("Bal" + stockBalance)
        /*         if (this.state.categItem === 'Drinks') {
        
                    const stockBalance = parseFloat(this.state.balanceStock) - parseFloat(this.state.qty);
        
                    if (parseFloat(stockBalance) < 0) {
        
                        alert("You can only sell " + this.state.balanceStock + " of this item!");
                        const input = document.getElementById("qty");
                        input.focus()
                        return false;
                    }
        
                } */
        else {
            return Object.keys(this.state).map(key => {
                if (key === "product") {
                    if (this.state.categItem === 'Food') {
                        this.cart.push({
                            product: (this.state.product).replace(/\s/g, " "), price: (this.state.price).replace(/\s/g, " "), qty: (this.state.qty).replace(/\s/g, " "),
                            total: (this.state.total)
                        });
                    }

                    if (this.state.categItem === 'Drinks') {
                        var ItemBalanceStock = parseFloat(this.state.balanceStock) - parseFloat(this.state.qty);

                        if (parseFloat(ItemBalanceStock) < 0) {

                            alert("You can only sell " + this.state.balanceStock + " of this item!");
                            const input = document.getElementById("qty");
                            input.focus()
                            return false;
                        }
                        else {

                            this.cart.push({
                                product: (this.state.product).replace(/\s/g, " "), price: (this.state.price).replace(/\s/g, " "), qty: (this.state.qty).replace(/\s/g, " "),
                                total: (this.state.total), balanceStock: ItemBalanceStock, prodId: this.state.prodId
                            });
                        }
                    }
                }
                // console.log("Cart stuff>>> "+this.state.product)
                this.setState({ price: 0, product: "", qty: "", total: 0, selectedProduct: "" }, function () {
                    console.log("Cart stuff after >>> " + this.state.selectedProduct)

                });
                //this.handleReset();
                // this.purchase();
                // this.setState({selectedProduct: " "});
                //console.log("cart values:" + JSON.stringify(this.cart));
                this.setState({ cart: this.cart });
                return null;
            })
        }
    }

    getGrandTotal() {
        var grandTotal = 0;
        for (let i = 0; i < this.cart.length; i++) {
            grandTotal += this.cart[i].total;
        }
        return grandTotal.toFixed(2);
    }

    removeItem = async (index, e) => {
        /*         let InnerState = [];
                for (let i in this.cart) {
                    let indexB = parseInt(i);
                    if (indexB === index) {
                        continue;
                    }
                    let row = InnerState[i];
                    InnerState.push(row);
                }
                this.cart = InnerState;
                this.setState({ cart: this.cart }); */

        this.cart.splice(index, 1);
        this.cart.filter((el, idx) => idx !== index);
        await this.setState({ cart: this.cart });
        console.log(">>>> Removed at index " + this.cart.length);
    }

    async handlePurchase() {
        if (this.state.cart.length === 0) {
            alert("Please select items before purchasing!");
            return false;
        }
        else {

            this.insertPurchase();
            this.updateProductStock();
            this.handleOpen.current.handleOpen();
        }
    }
    clearPurchase = () => {
        this.setState({ cart: [], sales: [], selectedProduct: '' });
        this.sales = [];
        this.getSalesToday();
        this.cart = [];
        document.getElementById('food').checked = false;
        document.getElementById('drinks').checked = false;
    }
    // mysale = [];
    str = "";

    getTodaySale = () => {
        let todaySales = 0;
        if (this.state.sales.length > 0) {
            {
                this.state.sales.map(sale => (
                    todaySales += sale.total
                ))

            }
            //    this.setState({todaySales: todaySales})
        }
        return this.numberFormatter.format(todaySales);
    }
    /*     voidSale=(id)=>{
            alert("Sale number"+id)
        } */
    goToInventory = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/inventory', state: {
                username: this.props.history.location.state.fullname, fullname: this.props.history.location.state.fullname,
                db: this.state.db, items: this.state.items, salesRecords: this.state.salesRecords
            }
        });
    }
    goToReports = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/reports', state: {
                username: this.props.history.location.state.fullname, fullname: this.props.history.location.state.fullname,
                db: this.state.db, items: this.state.items, salesRecords: this.state.salesRecords
            }
        });
    }
    appLogout = (e) => {
        e.preventDefault();
        this.props.history.push({ pathname: '/', state: { fullname: "" } })
    }
    numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    render() {
        return (
            <div className='mainDivHome'>
                <div style={{width:"100%"}}>
                    <span>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(67, 129, 67)" }} >Home</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} text="GoTo Inventory" value="GoTo Inventory" onClick={this.goToInventory}>Inventory</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} text="Reports" onClick={this.goToReports}>Reports</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(160, 46, 46)" }} text="Logout" onClick={this.appLogout}>Logout</Button>
                    </span>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <span style={{ fontStyle: "italic" }}><label>Welcome {this.props.history.location.state.fullname}</label></span>
                    <span style={{ fontStyle: "italic" }}><label>, today is {this.state.date}</label></span>
                </div>
                {/* <Form> */}
                <div style={{ paddingTop: "20px" }}>
                    <label><h3>Point Of Sale </h3></label>
                    <div>
                        <div style={{ paddingBottom: "10px" }}>
                            <span style={{ paddingRight: "10px" }}>
                                <label>
                                    <input style={{ marginRight: ".5rem", paddingLeft: "20px", paddingRight: "20px" }}
                                        type='checkbox'
                                        name='drinks'
                                        id='drinks'
                                        /* defaultChecked={true} */
                                        onChange={this.handleDrinksChange}
                                    />
                                    Drinks
                                </label>
                            </span>
                            <span style={{ paddingLeft: "10px" }}>
                                <label>
                                    <input style={{ marginRight: ".5rem", paddingLeft: "20px", paddingRight: "20px" }}
                                        type='checkbox'
                                        name='food'
                                        id='food'
                                        onChange={this.handleFoodChange}
                                    />
                                    Food
                                </label>
                            </span>
                        </div>
                    </div>
                    <div>
                    </div>
                    {/*                         <FormGroup>
                            <label for='products'>Select Product</label>
    
                             <Input
                                type='select'
                                name="products"
                               // mode="multiple"
                                // options={this.state.products}
                                value={this.state.selectedProduct}
                                // labelField='name'
                                // valueField='name'
                                // placeholder={this.state.selectedProduct}
                                onChange={this.setSelectChange}>
                                <option value="PLease select" >-- Please Select--</option>
                                {this.state.products.map(item => (
                                    <option value={item.name} id={item.name} >{item.name}</option>
                                ))}
                            </Input> 
                        </FormGroup> */}
                    <FormGroup>
                        <label for='products'>Search or select item</label>
                        <Select

                            options={this.state.items}
                            // value={this.state.selectedProduct}
                            maxMenuHeight="100%"
                            defaultInputValue={this.state.selectedProduct}
                            onChange={opt => this.setSelectChange(opt.label)}
                        ></Select>
                    </FormGroup>

                    <FormGroup>
                        <label for='price'>Unit Price</label>
                        <Input
                            type='text'
                            id='price'
                            name='price'
                            value={parseFloat(this.state.price).toFixed(2)}
                            onChange={this.setPrice}
                        />
                    </FormGroup>
                    {/*                      <FormGroup>
                            <label for='qty'>Enter Quantity</label>
                            <Input type='number'

                                maxLength={3}
                                id='qty'
                                name='qty'
                                keyboardType='numeric'
                                value={this.state.qty}
                                onChange={this.setQty} />
                        </FormGroup> */}
                    <label for='qty'>Quantity</label>
                    <FormGroup>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingBottom: "20px" }}>
                            {/* <div style={{width:"30%"}}> */}

                            <Input 
                          //  style={{ width:'45vw'}}
                            type='number'                           
                                maxLength={3}
                                id='qty'
                                name='qty'
                                keyboardType='numeric'
                                value={this.state.qty}
                                onChange={this.setQty} />
                            {/* </div>
                            <div style={{ width:"40%"}}> */}
                            <span style={{ paddingLeft:"3vw" }}>
                                <Button style={{ backgroundColor: "rgb(208, 247, 228)", color: "green", width:"40vw"}} text="AddItems+" onClick={() => this.addToCart()}>AddItems++</Button>
                            </span>
                            {/* </div> */}
                        </div>
                        {/*     </FormGroup>
                        <FormGroup> */}
                        <label for='total'>Total Price</label>
                        <Input type='text'
                            id='total'
                            name='total'
                            value={this.state.total.toFixed(2)}
                            onChange={this.setTotal} />
                    </FormGroup>
                    {/* <div style={{ paddingTop: "20px" }}>
                            <span >
                                <Button style={{ backgroundColor: "rgb(208, 247, 228)", color: "green", width: "100%" }} text="AddItems+" onClick={() => this.addToCart()}>AddItems++</Button>
                            </span>
                        </div>*/}
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <table style={{ width: "100%", borderBottom: "1px solid white" }}>
                        <tr style={{ height: "5px", borderBottom: "1px solid white" }}>
                            <th> Qty</th>
                            <th> Product</th>
                            <th> Price</th>
                            <th> Total</th>
                            <th style={{ textAlign: "right" }}> Remove</th>
                        </tr>
                        {this.cart.map((row, index) => {
                            return (<tr key={index} style={{ height: "25px", borderBottom: "1px solid white", width: "25px" }}>
                                <>
                                    <td style={{ borderColor: "white" }} ><Input type='text' value={row.qty}></Input></td>
                                    <td style={{ borderColor: "white" }}><Input type='text' value={row.product}></Input></td>
                                    <td style={{ borderColor: "white" }}><Input type='text' value={parseFloat(row.price).toFixed(2)}></Input></td>
                                    <td style={{ borderColor: "white" }}><Input type='text' value={parseFloat(row.total).toFixed(2)}></Input></td>
                                    <td style={{ borderColor: "white" }}><button style={{ background: "none", color: "inherit", border: "none", float: "right", width: "10px" }}>
                                        <img src={deleteIcon} alt="Remove" style={{ height: "25px", width: "30px", float: "right" }}
                                            onClick={() => this.removeItem(index)}></img>
                                    </button></td>
                                </>
                            </tr>
                            );
                        })
                        }
                    </table>
                    <div style={{ paddingTop: "10px", paddingLeft: "5px", paddingBottom: "10px" }}><label style={{ fontWeight: "bold", textAlign: "right" }}>Grand Total: R{this.getGrandTotal()}</label></div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                    <span >
                        <Button style={{ backgroundColor: "rgb(208, 247, 228)", width: "100%" }} text="Purchase" onClick={() => this.handlePurchase()}>{this.strPurchase} </Button>
                    </span>
                </div>
                <div style={{ paddingTop: "20px" }}>
                    <span style={{ paddingTop: "20px", paddingLeft: "5px", fontWeight: "bold" }}>
                        <label><b>Sales today: R{this.getTodaySale()}</b> </label>
                    </span>
                </div>
                <div style={{ paddingTop: "20px" }}>
                    <span >
                        <Button style={{ backgroundColor: "rgb(208, 247, 228)", width: "100%", color: "brown" }} text="Overrides" onClick={() => this.handleOverrides()}>-- Void --</Button>
                    </span>
                </div>
                {/* </Form> */}
                <div id="receipt" >
                    {<Receipt
                        clearPurchase={this.clearPurchase}
                        loadProduct={this.loadProduct}
                        ref={this.handleOpen}
                        shopname={this.state.shopname}
                        username={this.state.username}
                        date={this.state.dateWithoutSecond}
                        cart={this.state.cart.map((ms) => <div className="cart">{ms.qty + " * " + ms.product + " ~ R" + ms.total.toFixed(2)}</div>)
                        }
                        total={this.getGrandTotal()}
                    />}
                </div>
                <div id="override" >
                    {<Override
                        ref={this.overrideOpen}
                        shopname={this.state.shopname}
                        username={this.state.username}
                        date={this.state.dateWithoutSecond}
                        sales={this.state.salesRecords}
                    />}
                </div>

            </div>
        );
    }
}

export default withRouter(Home);
