import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
//import Select from "react-dropdown-select";
import Select from 'react-select';
import axios from "axios";
import './Inventory.css'

class Inventory extends Component {
    constructor(props) {
        super(props);
        const today = new Date().toLocaleDateString('en-uk', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
        this.state = {
            date: today,
            products: [],
            selectedProduct: "",
            selectedCategory: "",
            selectedStatus: "",
            foods: [],
            drinks: [],
            qty: 0,
            price: 0,
            productId: 0,
            addQty: "",
            category: "",
            product: "",
            newCateg: "",
            feedBackQty: "",
            feedBackAddProd: "",
            products: [],
            sales: [],
            items: [],
            salesRecords: [],
            // status: "",
            db: "",
            addedQty: "",
            items: ""
        }
    }
    foods = [];
    drinks = [];
    items = [];
    categories = ["Food", "Drinks"];
    statuses = ["Active", "Inactive"];

    loadProducts = async (e) => {
        let config = {
            headers: { 'Accept': 'application/json' },
            params: { "db": this.props.history.location.state.db }

        }
        //const url = "http://localhost/reactcrudphp/api/getProduct.php";
        const url = "https://phpapi.bktsystems.com/api/getAllProducts.php";
        const response = await axios.get(url, config)

        if (Array.isArray(response.data)) {
            /*             //  console.log(">>>>>"+response.data)
                        this.setState({ products: response.data });
                        this.setState({ data: response.data });
                        //  console.log(this.state.products);
                        Object.keys(response.data).map((item, index) => {
                            if (response.data[item].category === 'Food') {
                                this.foods.push(response.data[index]);
                            }
                            if (response.data[item].category === 'Drinks') {
                                this.drinks.push(response.data[index]);
                            }
                        }) */
            //  console.log(">>>>>"+response.data)
            this.setState({ products: response.data });
            this.setState({ data: response.data });
            //  console.log(this.state.products);

            Object.keys(response.data).map((item, index) => {
                var obj = {};
                if (response.data[item].category === 'Food') {
                    this.foods.push(response.data[index]);
                }
                if (response.data[item].category === 'Drinks') {
                    this.drinks.push(response.data[index]);
                }
                obj["label"] = response.data[item].name
                obj["value"] = response.data[item].name
                this.items.push(obj)
            })

            this.setState({ username: this.props.history.location.state.username, addQty: "", selectedCategory: "", items: this.items })
            document.getElementById('category').style.display = "block";
            document.getElementById('categoryNew').style.display = "none";
            this.items = [];
        }
    }

    componentDidMount = async (e) => {
        this.loadProducts();
        // console.log("DBBBBB"+this.state.addQty)
        this.setState({ db: this.props.history.location.state.db, items: this.props.history.location.state.items, salesRecords: this.props.history.location.state.salesRecords })
    }
    getQty = () => {
        return "Quantit: 2"
    }
    clearForm = () => {

        this.setState({ selectedCategory: "", category: "", newCateg: "", productId: 0, product: "", selectedStatus: "", qty: 0, status: "", price: 0.00, items: "" });
        document.getElementById('new').checked = false;
        document.getElementById('products').disabled = false;
        document.getElementById('products').style.display = "block";
        document.getElementById('addStock').disabled = false;
        this.items = [];
        this.loadProducts();
    }

    setSelectChange = (e) => {
        var index = this.state.products.findIndex(p => p.name === e);
        const price = this.state.products[Object.keys(this.state.products)[index]].price;;
        const product = this.state.products[Object.keys(this.state.products)[index]].name;
        const qty = this.state.products[Object.keys(this.state.products)[index]].stock;
        const category = this.state.products[Object.keys(this.state.products)[index]].category;
        const productId = this.state.products[Object.keys(this.state.products)[index]].id;
        const StrSstatus = this.state.products[Object.keys(this.state.products)[index]]['status'];
        document.getElementById('stock').setAttribute("disabled", "disabled");
        // Set the selected price value in state
        this.setState({ price: parseFloat(price).toFixed(2), product: product, qty: qty, selectedCategory: category, productId: productId, selectedStatus: StrSstatus }, function () {
            //   console.log("Price is " + price)
        });
    }
    submitProduct = async (e) => {
        let strOption = "";

        if (this.state.newCateg !== "") {
            strOption = "new";
        }
        if (this.state.selectedCategory !== "") {
            strOption = "edit";
        }
        e.preventDefault();

        if ((strOption === "") && ((this.state.selectedCategory === "") || (this.state.product === ""))) {

            alert("Please select a product to edit or choose add new option!");
            return false;
        }
        if ((this.state.selectedCategory === "") && (this.state.product !== "") && (this.state.productId === 0) && (this.state.newCateg !== "")) {            
            let productFound = false;
            {
                this.state.items.map(item => {
                    if (item.value.replace(/\s/g, '').toLowerCase() === this.state.product.replace(/\s/g, '').toLocaleLowerCase()){
                       // console.log(item.value+" --- "+this.state.product)
                        return productFound = true;
                    }
                        
                })
            }

            if (productFound === true) {
                alert("The item you are trying to load exists already, duplicate items are not allowed!")
                return false;
            }
            else {
                const userData = {
                    'name': this.state.product,
                    'price': this.state.price,
                    'stock': this.state.qty,
                    'category': this.state.newCateg,
                    'db': this.state.db
                };
                 var qs = require('qs');
                // const url = "http://localhost/reactcrudphp/api/productInsert.php";
                const url = "https://phpapi.bktsystems.com/api/productInsert.php";
                const response = await axios.post(url, qs.stringify(userData))
                // console.log(response.data);
                this.setState({ selectedCategory: "", category: "", newCateg: "", product: "", selectedStatus: "", qty: 0, status: "", price: 0.00, items: "" });
                document.getElementById('new').checked = false;
                document.getElementById('products').disabled = false;
                document.getElementById('products').style.display = "block";
                document.getElementById('categoryNew').style.display = "none";
                document.getElementById('category').style.display = "block";
                document.getElementById('addStock').disabled = false;
                if (response.data.includes("Product record saved")) {
                    document.getElementById('feedBackAddProd').style.display = "block"
                    this.setState({ feedBackAddProd: "Product record saved!" })
                }
                document.getElementById('feedBackAddProd').innerHTML = this.state.feedBackAddProd;
                setTimeout(function () { document.getElementById('feedBackAddProd').innerHTML = "" }, 2500);
                this.items = [];
                this.loadProducts(); 
            }
        }
        else {
            if((this.state.newCateg === "") &&(this.state.productId !==0)){
                let newQty = 0
                // console.log("addState value"+this.state.addQty)
                if ((this.state.addQty === 0) || (this.state.addQty === "")) {
                    //   console.log("-----here"+newQty)
                    newQty = this.state.qty
                }
                else {
                    newQty = this.state.addQty
                }
                // console.log("new qty after"+newQty)
                const userData = {
                    'name': this.state.product,
                    'price': this.state.price,
                    'stock': newQty,
                    'category': this.state.selectedCategory,
                    'id': this.state.productId,
                    'status': this.state.selectedStatus,
                    'db': this.state.db
                };
                var qs = require('qs');
                // const url = "http://localhost/reactcrudphp/api/productInsert.php";
                const url = "https://phpapi.bktsystems.com/api/productUpdate.php";
                const response = await axios.post(url, qs.stringify(userData))
                //  console.log(response.data);
                if (response.data.includes("Product record updated")) {
                    document.getElementById('addProductQtyFeedBack').style.display = "block"
                    this.setState({ feedBackQty: "Record updated", selectedCategory: "" })
                }
                newQty = 0;
                this.setState({ selectedCategory: "", category: "", newCateg: "", product: "", productId:0, addedQty: "", qty: 0, addQty: newQty, selectedStatus: "", price: 0.00 });
                document.getElementById('addProductQtyFeedBack').innerHTML = this.state.feedBackQty
                document.getElementById('new').checked = false;
                document.getElementById('products').disabled = false;
                document.getElementById('products').style.display = "block";
                document.getElementById('addStock').disabled = false;
                setTimeout(function () { document.getElementById('addProductQtyFeedBack').innerHTML = "" }, 2500);
                this.loadProducts();                
            }
        }
    }

    deleteProduct = async (e) => {

        if (this.state.productId !== 0) {
            if (window.confirm("you are about to deactivate this item")) {
                const userData = {
                    'id': this.state.productId,
                    'db': this.state.db
                };
                var qs = require('qs');
                // const url = "http://localhost/reactcrudphp/api/productInsert.php";
                const url = "https://phpapi.bktsystems.com/api/deleteProduct.php";
                const response = await axios.post(url, qs.stringify(userData))
                this.loadProducts();
                this.setState({ selectedCategory: "", category: "", product: "", qty: 0, status: "", price: 0.00 });
            }
        }
        else {
            alert("Please select an item to deactivate!");
            return false;
        }
    }

    setQty = (e) => {

        this.setState({ qty: e.target.value }, function () {
        });
    }
    setPrice = (e) => {
        // const quantity = e.target.value;
        this.setState({ price: e.target.value }, function () {
        });
    }
    setProduct = (e) => {
        // const quantity = e.target.value;
        this.setState({ product: e.target.value }, function () {
        });
    }
    setCategoryChange = (e) => {
        // const quantity = e.target.value;
        this.setState({ category: e.target.value }, function () {
        });
    }

    setAddQty = (e) => {
        if ((e.target.value.length > 3) || (parseFloat(e.target.value) === 0)) {
            alert("Zero and 3 digit numbers are not valid input, please correct!")
            return false
        }
        else {
            var quantity = e.target.value;
            var oldQty = parseFloat(this.state.qty);
            const newQty = (parseFloat(quantity) + oldQty)
            this.setState({ addedQty: quantity, addQty: newQty }, function () {
            });
        }

    }
    handleNewProduct = (e) => {
        if (e.target.checked === true) {
            this.setState({ selectedStatus: "Active" });
            document.getElementById("category").style.display = "none";
            document.getElementById("categoryNew").style.display = "block";
            /* document.getElementById('products').setAttribute("disabled", "disabled"); */
            document.getElementById('products').style.display = "none";
            document.getElementById('addStock').setAttribute("disabled", "disabled");
            document.getElementById('stock').disabled = false;


        }
        else {
            document.getElementById("category").style.display = "block";
            document.getElementById("categoryNew").style.display = "none";
            this.setState({ selectedStatus: "" });
            document.getElementById('products').disabled = false;
            document.getElementById('products').style.display = "block";
            document.getElementById('addStock').disabled = false;

        }
        this.setState({ product: '', price: 0.00, qty: '' })

    }
    setCategoryNewChange = (e) => {
        this.setState({ newCateg: e.target.value }, function () {
        });
    }
    setProductStatus = (e) => {
        this.setState({ selectedStatus: e.target.value }, function () {
        });
    }
    goToReports = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/reports', state: {
                username: this.props.history.location.state.fullname, fullname: this.props.history.location.state.fullname,
                db: this.state.db, items: this.state.items, salesRecords: this.state.salesRecords
            }
        });
    }
    goToHomePage = (e) => {
        this.props.history.push({ pathname: '/home', state: { fullname: this.props.history.location.state.fullname, db: this.state.db } })
    }
    appLogout = (e) => {
        e.preventDefault();
        this.props.history.push({ pathname: '/', state: { fullname: "", db: this.state.db } })
    }

    render() {
        return (
            <div className="mainDivHome">
                <div style={{width:"100%"}}>
                    <span>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} onClick={this.goToHomePage}>Home</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(67, 129, 67)" }}>Inventory</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige" }} text="Reports" onClick={this.goToReports}>Reports</Button>
                        <Button style={{paddingLeft:"5px", width: "25%", background: "none", border: "none", backgroundColor: "beige", fontWeight: "bold", color: "rgb(160, 46, 46)" }} text="Logout" onClick={this.appLogout}>Logout</Button>
                    </span>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <span style={{ fontStyle: "italic" }}><label>User: {this.props.history.location.state.username}</label></span>
                    <span style={{ fontStyle: "italic" }}><label>, today is {this.state.date}</label></span>
                </div>
                <Form>
                    <div style={{ paddingTop: "20px" }}>
                        <label id="addProductQtyFeedBack" style={{ color: "yellow", display: "none", fontWeight: "bold" }}></label>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <label id="feedBackAddProd" style={{ color: "yellow", display: "none", fontWeight: "bold" }}></label>
                    </div>

                    <div>
                        <span>
                            <label >
                                <input style={{ marginRight: ".5rem", paddingLeft: "20px", paddingRight: "20px" }}
                                    type='checkbox'
                                    name='new'
                                    id='new'
                                    text="Add new"
                                    /* defaultChecked={true} */
                                    onChange={this.handleNewProduct}
                                />
                                Add New Product
                            </label>
                        </span>
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ borderColor: "white" }}><th>Select</th></td>
                                <td style={{ borderColor: "white" }}>

                                    {/*               <FormGroup>
                                        <Input
                                            id='products'
                                            type='select'
                                            name="products"
                                            value={this.state.selectedProduct}
                                            //  options={this.state.products}
                                            //  labelField='name'
                                            //  valueField='name'
                                            onChange={this.setSelectChange}>
                                            <option value="PLease select" >-- Please Select--</option>
                                            {this.state.products.map(item => (
                                                <option value={item.name} id={item.name} >{item.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup> */}
                                    <div id="products">
                                        <Select
                                            options={this.state.items}
                                            // value={this.state.selectedProduct}
                                            maxMenuHeight="100%"
                                            // isOptionDisabled = {true}
                                            defaultInputValue={this.state.selectedProduct}
                                            onChange={opt => this.setSelectChange(opt.label)}
                                        ></Select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderColor: "white" }}><th>Category</th></td>
                                <td style={{ borderColor: "white" }}><FormGroup>
                                    <Input
                                        type='select'
                                        name="category" id="category"
                                        value={this.state.selectedCategory} onChange={this.setCategoryChange}>
                                        <option value="PLease select" >-- Please Select--</option>
                                        {this.categories.map(categ => (
                                            <option value={categ} id={categ} >{categ}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                    <Input
                                        type='select' style={{ display: "none", width: "100%" }} value={this.state.newCateg} name="category" id="categoryNew" onChange={this.setCategoryNewChange}>
                                        <option value="PLease select" >-- Please Select--</option>
                                        {this.categories.map(categ => (
                                            <option value={categ} id={categ} >{categ}</option>
                                        ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderColor: "white" }}><th>Product</th></td>
                                <td style={{ borderColor: "white" }}><Input type="text" value={this.state.product} onChange={this.setProduct}></Input></td>
                            </tr>
                            <tr>
                                <td style={{ borderColor: "white" }}><th>Price</th></td>
                                <td style={{ borderColor: "white" }}><Input type="number" keyboardType='numeric' value={this.state.price} onChange={this.setPrice}></Input> </td>
                            </tr>
                            <tr>
                                <td style={{ borderColor: "white" }}><th>Stock</th></td>
                                <td style={{ borderColor: "white" }}><Input id="stock" type="number" keyboardType='numeric' value={this.state.qty} onChange={this.setQty}></Input> </td>
                            </tr>
                            <tr>

                                <td style={{ borderColor: "white" }}><th>Add Stock</th></td>
                                <td style={{ borderColor: "yellow", borderStyle: "solid", borderWidth: "2px" }} >
                                    <Input id="addStock" type="number" maxLength={3} keyboardType='numeric' value={this.state.addedQty} onChange={this.setAddQty}></Input> </td>
                            </tr>

                            <tr>
                                <td style={{ borderColor: "white" }}><th>Status</th></td>
                                {/* <td style={{ borderColor: "white" }}><Input type="text" value={this.state.status} onChange={this.setProductStatus}></Input> </td> */}
                                <td style={{ borderColor: "white" }}>
                                    <Input
                                        type='select'
                                        name="status" id="status"
                                        value={this.state.selectedStatus} onChange={this.setProductStatus}>
                                        <option value="PLease select" >-- Please Select--</option>
                                        {this.statuses.map(status => (
                                            <option value={status} id={status} >{status}</option>
                                        ))}
                                    </Input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <div >
                            <Button style={{ backgroundColor: "rgb(208, 247, 228)", color: "rgb(255, 195, 0)", width: "100%" }} text="Clear" onClick={() => this.clearForm()}>Clear</Button>
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ backgroundColor: "rgb(208, 247, 228)", width: "100%" }} text="Submit" onClick={this.submitProduct}>Submit</Button>
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ backgroundColor: "rgb(208, 247, 228)", color: "red", width: "100%" }} text="Delete" onClick={this.deleteProduct}>Delete</Button>
                        </div>
                        {/*     <span >
                        <Button text="Reports" onClick={this.goToReports}>Reports</Button>
                    </span> */}
                    </div>
                </Form>
            </div>
        )
    }
}
export default withRouter(Inventory)