import React from "react";
import { Component } from "react";
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import Modal from 'react-modal';

class Override extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            username: ""
        }
    }

    overrideClose = () => {
        this.setState({ open: false });
        // this.props.clearPurchase();
    };

    overrideOpen = () => {
        this.setState({ open: true });

    };
    
    componentDidMount = () => {
        //console.log("sales today are " + this.props.sales)
    }
    voidSale = (id) => {
        alert("Sale number " + id)
    }
    getSalesItems = (items) => {
        let salesItems = JSON.parse("[" + items + "]");
        return (salesItems.map(function (sl, i) {
            return <div><div>{sl.qty} * {sl.product}</div>
                <div>R{parseFloat(sl.price).toFixed(2)} each ~ Total: R{parseFloat(sl.total).toFixed(2)}</div></div>
        }))
    }
    render() {

        return (
            <div>

                <div >
                    <Modal ariaHideApp={false} isOpen={this.state.open} onClose={this.overrideClose}>
                        <>
                            <div >
                                <span className="shopname"><h4>{this.props.shopname}</h4></span>
                            </div>
                            <h4><u>Recent Sales</u></h4>
                            <div style={{ paddingTop: "5px" }}>
                                <span className="date">Date: {this.props.date}</span>
                            </div>
                            <div style={{ borderBottom: "ridge", paddingBottom: "20px" }}>
                                <span className="username">Cashier: {this.props.username}</span>
                            </div>
                            <div style={{ paddingTop: "5px" }}>
                                {this.props.sales.map((ms) => <div className="sales"><div>Sale #: {ms.id}</div><div>Date: {ms.modified}</div><div>User: {ms.username}</div>
                                    {/* <div>{ms.items} </div> */}
                                    {this.getSalesItems(ms.items)}
                                    <div style={{ paddingTop: "10px" }}><Button style={{ backgroundColor: "rgb(230, 145, 105)", width: "100%", height: "40px", color: "white" }} text="Void" onClick={() => this.voidSale(ms.id)}>-- Void --</Button></div>
                                    <hr /></div>)}
                            </div>
                            {/*          <div>
                                <span>
                                {this.props.sales.map((ms) => <div className="cart">{ms.qty + " * " + ms.product + " ~ R" + ms.total.toFixed(2)}
                                    <span><input type="button">Delete</input></span>
                                </div>)}
                            </span>
                            
                            </div> */}
                            {/*           <div style={{ paddingTop: "20px" }}>
                                <span className="cart">Item Lines {this.props.cart}</span>
                            </div>
                            <div style={{ paddingTop: "20px", borderBottom: "ridge" }}>
                                <span className="total">Total: <b>R{this.props.total}</b></span>
                            </div> */}
                        </>
                        <div style={{ paddingTop: "20px" }}>
                            <Button style={{ width: "100%" }}
                                onClick={this.overrideClose}>
                                Close
                            </Button>
                        </div>
                    </Modal>
                </div>

            </div>
        );

    }

}
export default Override